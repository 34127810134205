<template>
    <div>
        <CRow>
            <CCol sm="4">
                
                <CRow>
                    <CCol sm="12">
                        <md-field>
                            <label>Name</label>
                            <md-input v-model="jobType.name"></md-input>
                        </md-field>
                        <div>
                            <md-radio v-model="jobType.type" value="Distance">Distance</md-radio>
                            <md-radio v-model="jobType.type" value="Hourly" class="md-primary">Hourly</md-radio>
                        </div>
                      
                    </CCol>
                </CRow>
                
            </CCol>
            <CCol sm="8">
            </CCol>
        </CRow>
        <md-button class="md-fab md-primary" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>
import firebase from '../../Firebase'
export default {
    name: 'NewDispatcher',
    
    data() {
        return {
           
            jobType: {
                name:null,
                type:null
            },
            jobTypeRef:  firebase.firestore().collection('job-types'),
            
           
        }
    },
    
    methods: {
        add(){
            let th = this;
            this.jobTypeRef.add(th.jobType)
            .then(function(docRef) {
                console.log("Tutorial created with ID: ", docRef.id);
                th.$router.push({path: `/app/job-types`})
            })
            .catch(function(error) {
                console.error("Error adding Tutorial: ", error);
            });;
        },
       
       
    },
    computed: {
      
       
    },
    created () {
        
    },
}
</script>  